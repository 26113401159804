import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"

export default function FacilityPage() {
  return (
    <React.Fragment>
      <Seo
        title="Largest volume mailer in the Herndon VA region"
        description="Direct Mail Processing Facility located in Herndon Virginia. Secure mail facility."
      />
      <div id="wrapper">
        <div id="topper">
          <div id="logo">
            <Link to="/">
              <img
                src="/wordmark-dark.svg"
                width="234"
                height="108"
                alt="Big Eye Direct"
              />
            </Link>
          </div>
          <div id="contact">
            <h2>703-955-3017</h2>
            <p>
              <a href="mailto:damon@bigeyedirect.com">info@bigeyedirect.com</a>
            </p>
          </div>
        </div>
        <div id="content">
          <div id="nav">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/services/">SERVICES</Link>
              </li>
              <li className="active">
                <Link to="/facility/">OUR FACILITY</Link>
              </li>
              <li>
                <Link to="/security/">SECURITY</Link>
              </li>
              <li>
                <Link to="/about/">ABOUT US</Link>
              </li>
              <li className="endNav">
                <Link to="/contact/">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
          <div id="flasharea">
            {" "}
            <img src="/images/photo_facility.jpg" width="926" height="216" />
          </div>
          <div id="textarea">
            <h2>Our Facility</h2>

            <h3>
              <img
                src="/images/Ready-to-mail3.jpg"
                width="300"
                height="199"
                // align="left"
                className="padRtBottom"
              />
            </h3>
            <p className="leftpadding">
              BIGEYE Direct conducts operations in a secure, climate controlled,
              state-of-the-art facility conveniently located near Dulles
              International airport in Herndon, Virginia.&nbsp; The plant is
              fully staffed with 2 expanded shifts providing 20 hours of daily
              production.&nbsp; Inserted mail capacity averages a half a million
              pieces per day while capacity for addressing &amp; mailing
              self-mailers exceeds a million pieces per day.
            </p>
            <p className="leftpadding">
              As the largest volume mailer in the region, our clients benefit
              from in-plant postal processing, drop shipping, and daily
              co-mingled mail pick-up for both first className and
              standard/non-profit mail. As an environmentally conscious
              organization, BIGEYE recycles every piece of non-sensitive waste.
              <br />
              {/* <a
                href="#"
                onclick="MM_openBrWindow('productionVideo.html','','scrollbars=yes,resizable=yes,width=700,height=500')"
              >
                Click here for video footage of actual production
                <br />
              </a>
              <a
                href="#"
                onclick="MM_openBrWindow('two-wayMatch.html','','scrollbars=yes,resizable=yes,width=700,height=500')"
              >
                Two-way Match Quality Control Video
              </a> */}
            </p>
            <div className="clear"></div>
          </div>
        </div>
        <div id="footer">
          <a
            rel="noopener noreferrer"
            href="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Drive+Herndon,+VA+2017&amp;sll=37.0625,-95.677068&amp;sspn=52.637906,88.330078&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;z=17"
            target="_blank"
          >
            13860 Redskin Drive • Herndon, VA 20171
          </a>
        </div>
        <div id="copyright">
          ©2011 BIGEYE Direct. All rights reserved. Sitemap
        </div>
      </div>
    </React.Fragment>
  )
}
